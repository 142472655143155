<template>
	<div outlined fluid class="d-flex flex-column" :style="{height: `calc( 100vh - (72px + ${ $vuetify.breakpoint.mobile ? '56px' : '0px' }) )`}">
    <div class="chat-container flex-grow-1 pb-8">
			<Message v-for="message in mensajes" :key="message.ref" :message="message" />
    </div>
    <MessageInput :style="`padding: 0 ${xPad};`" class="flex-grow-0" :send-msg="sendMsg" />
  </div>
</template>

<script setup>
import { ref, watch, computed, getCurrentInstance } from "vue";
import axios from "@/utils/axiosWrapper.ts";
import Message from '../components/Mensaje.vue'
import MessageInput from '../components/MensajeInput.vue'
import { useIntervalFn, useTimeoutFn, useWebSocket } from '@vueuse/core'

const { data: mensajes, pending, refresh : getMessages } = axios('tickets', { default: () => ([]) })

// useIntervalFn( getMessages, 1000 * 60 )

const vuetify = getCurrentInstance().proxy.$vuetify
const store = getCurrentInstance().proxy.$store

const xPad = computed(() => {
  if ( vuetify.breakpoint.smAndDown ) return '0px'
  if ( vuetify.breakpoint.mdOnly ) return '10%'
  if ( vuetify.breakpoint.lgAndUp ) return '20%'
})

const { refresh : readMessages } = axios({
  url: 'tickets',
  method: 'PATCH',
  lazy: true,
})

// useTimeoutFn( readMessages, 1000 * 5 )

const { data : lastMsg, send : sendMsg } = useWebSocket(`wss://chat.donvatio.es:8080/ws/${ store.getters.getTenant.slug }/${ store.getters.getTokenPayload.refcanal }`)

watch( lastMsg, () => {
  console.log('MENSAJE RECIBIDO');
  getMessages();
  readMessages();
})

</script>

<style lang="scss" scoped>
.chat-container {
  box-sizing: border-box;
  min-height: 200px;
  // max-height: 600px;
	gap: 10px;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column-reverse;
  background-color: #e4e4e4;
  padding: 0 v-bind(xPad);
}

.theme--dark .chat-container {
  background-color: #272727;
}
</style>