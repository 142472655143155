<template>
		<component v-model="val" v-bind="{...$props, ...$attrs}" v-on="$listeners" :is="types[input.type]" />
</template>

<script>

import * as types from './customInputs/index.js'

export default {
	// props: {
	// 	value : { required: true },
	// 	input : Object,
	// },
	props: ['input', 'value'],
	computed: {
		val: {
			get() { return this.value },
			set(v) { this.$emit('input', v) }
		}
	},
	data() {
		return {
			types
		}
	},
}
</script>