export default {
  state: () => ({
    menu: [],
  }),
  getters: {
    getMenu: (state) => state.menu,
  },
  mutations: {
    setMenu(state, menu) {
      state.menu = menu;
    },
  },
  actions: {
    setMenu({ commit }, menu) {
      commit("setMenu", menu);
    },
  },
};
