<template>
  <v-combobox v-model="val" outlined clearable hide-details="auto" v-bind="{...input, ...$attrs}"></v-combobox>
</template>

<script>
export default {
	props: ['value', 'input'],
	computed: {
		val: {
			get() { return this.value },
			set(v) { this.$emit('input', v?.value || v ) }
		}
	},
};
</script>
