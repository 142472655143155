<template>
  <div>

    <div class="d-flex pa-2" style="gap: 10px">

      <v-textarea
        v-model="message.msg"
        filled
        auto-grow
        rows="1"
        hide-details
        dense
        placeholder="Escribe aquí tu mensaje..."
      >
      </v-textarea>

      <v-btn fab small color="primary" @click.stop="send" :disabled="message.msg == '' && message.files.length == 0" >
        <v-icon small>mdi-send</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    refcontrato: Number | String,
  },
  components: {
    DragAndDrop: () => import("@/components/DragAndDrop.vue"),
  },
  data() {
    return {
      message: {
        showFiles: false,
        msg: "",
        files: [],
      },
    };
  },
  methods: {
    send() {
      const formData = new FormData();
      if (!this.message.msg && this.message.files.length == 0) return;
      // formData.append("token", this.$store.getters.getToken);
      formData.append("refcontrato", this.refcontrato);
      let msg = this.message.msg;
      msg = msg.replace(
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig,
        (url) => {
          if (!/^https?:\/\//i.test(url)) url = "https://" + url;
          return `<a target="_blank" href="${url}">${url}</a>`;
        }
      );
      msg = msg.replace(/\r?\n/g, '<br />');
      
      formData.append("mensaje", msg);
      // for (let i = 0; i < this.message.files.length; i++) {
      //   formData.append("documentos", this.message.files[i]);
      // }
      axios({
        method: "POST",
        url: `/chat/${this.refcontrato}`,
        data: formData,
      })
        .then(({ data }) => {
          this.cancelar()
          this.$emit("reload");
        })
        .catch((err) => console.error(err));
    },
    cancelar() {
      this.message.showFiles = false;
      this.message.msg = "";
      this.message.files = [];
    },
  },
};
</script>
