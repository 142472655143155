<template>
  <v-alert :elevation="1" colored-border border="bottom" :color="color"
    :class="{ message: true, own: own, 'pa-0': true, 'msg--caret': true }" >
    <v-card-subtitle class="py-2">
      <span :style="`color: ${color}`" class="font-weight-bold mr-2">{{ own ? message.nombrecanal :
        message.denombre }}</span>
      <span> {{ date }} </span>
      <v-icon v-if="message.leidoadm && own" class="float-right" small color="info" >mdi-check-all</v-icon>
      <!-- <status-chip class="ml-1" x-small :value="message.tipo" v-if="message.tipo" /> -->
    </v-card-subtitle>

    <v-divider />

    <div v-if="message.mensaje" class="px-4 py-2 msg--text" v-html="message.mensaje" />
    <template v-for="doc in message.documentos">
      <!-- <img v-if="['.jpg', '.png', '.gif', '.jpeg', '.svg', '.webp'].includes(message.extension)" :src="message.url" class="msg--img pa-1" /> -->
      <v-chip label @click="descargarArchivo(doc)" color="primary" outlined class="ma-1 mt-0">
        <v-icon left>mdi-download</v-icon>
        {{ doc.archivo }}
      </v-chip>
    </template>

  </v-alert>
</template>

<script>
import { parseDate, downloadFile } from "@/utils/index.js";
import extensions from "@/utils/mimeExtensions.js";
import pallete from "@/utils/colorPallete.js";

export default {
  props: {
    message: {
      type: Object,
    },
  },
  data() {
    return {
      deleting: false,
    };
  },
  components: {
  },
  computed: {
    date() {
      if (this.message.fecha)
        return new Date(this.message.fecha.replace('T', ' ')).toLocaleString()
      return ''
    },
    own() {
      return (
        // this.$store.getters.getTokenPayload.refcanal == this.message.refcolaborador
        this.message.tipo == 'COLABORADOR'
      );
    },
    color() {
      return pallete[ ((this.message.de % pallete.length) + pallete.length) % pallete.length ];
    },
    comercial() {
      return this.message.idUsuario == this.message.idComercial;
    },
  },
  methods: {
    parseDate,
    descargarArchivo({ url, archivo: nombre }) {
      axios({
        method: "GET",
        url: `/tickets/${url}`,
        responseType: "blob",
      }).then((res) => {
        let extension = nombre
          .split(".")
        [nombre.split(".").length - 1].toLowerCase();

        let file = new File([res.data], nombre, {
          type: extensions[extension],
        });

        let download = true;
        if (["pdf", "png", "jpg", "jpeg", "gif"].includes(extension))
          download = false;

        downloadFile(file, !download);
        this.downloadingFile = false;
        // window.open(fileURL);
      });
    },
    async eliminar() {
      if (! await this.$refs.confirm.open("Eliminar mensaje", "¿Seguro que quieres eliminar este mensaje?", { color: 'error', confirmColor: 'error', })) return;
      this.deleting = true;
      axios({
        method: "delete",
        url: `${process.env.VUE_APP_API_URL}/chat/${this.message.idMensaje}`,
      }).then((res) => {
        this.$emit("reload");
        this.deleting = false;
      });
    },
  },
};
</script>

<style lang="scss">
.message {
  align-self: start;
  // min-width: 300px;
  max-width: calc(100% - 20px);

  .menu-btn {
    float: right;
  }

  margin-bottom: 3px;

  .v-alert__border {
    border-width: 1.25px;
  }

  .msg--img {
    display: inline-flex;
    width: 100%;
    max-width: 300px;
  }
}

.own {
  align-self: end;

  .menu-btn {
    float: left;
  }

  .msg--text {
    // text-align: right;
  }
}

// .v-alert__content {
//   display: flex;
//   flex-direction: column;
// }

.d-grid {
  display: grid;
  // grid-gap: 10px;
}
</style>
