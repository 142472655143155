import store from '@/store/index.js'

const routes = {
  path: "/auth",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "login",
      name: "Login",
      component: () => import("./views/Login.vue"),
      beforeEnter: (to, from, next) => {
        if (store.getters.isLoggedIn) next({ name: "Inicio" });
        else next();
      },
    },
  ],
};

export default routes;
