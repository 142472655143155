<template>
	<v-card outlined>
		<v-card-title class="primary--text" >{{ $t('contratos.documentos') }}</v-card-title>
		<v-divider></v-divider>
		<v-simple-table>
			<thead>
				<tr>
					<th> {{$t('contratos.tipo')}} </th>
					<th> {{$t('contratos.nombre')}} </th>
					<th> {{$t('contratos.fecha')}} </th>
					<th></th>
				</tr>
			</thead>

			<tbody>
				<tr :key="doc.REF" v-for="doc in docs" >
					<td>{{ doc.tipo }}</td>
					<td>{{ doc.NOMBRE }}</td>
					<td>{{ parseDate(doc.FECHA, false, true, true) }}</td>
					<td>
						<div class="d-flex flex-wrap align-center" style="gap: 4px" >

							<audio v-if="isAudio(doc.NOMBRE)"  controls  :src="doc.URL"></audio>

							<!-- <v-btn  @click="download(doc)" rounded color="primary" small >
								<v-icon left>mdi-download</v-icon>
								{{ $t('common.descargar') }}
							</v-btn> -->
						</div>
					</td>
				</tr>
			</tbody>
		</v-simple-table>
		<v-divider></v-divider>
		<div class="pa-2">
			<v-dialog max-width="800" >
				<template v-slot:activator="{ on, attrs }" >
					<v-btn color="primary" rounded v-on="on" v-bind="attrs" >
						<v-icon left>mdi-upload</v-icon>
						{{ $t('common.subir') }}</v-btn>
					</template>

					<v-card>
						<v-card-title>Sube aquí los archivos</v-card-title>
						<DragAndDrop v-model="documento" />
						<v-divider />
						<div class="pa-2 d-flex ">
							<v-spacer />
							<v-btn color="primary" @click.stop="enviar" >Enviar</v-btn>
						</div>
					</v-card>

					</v-dialog>
		</div>
	</v-card>
</template>

<script>
import { parseDate, downloadFile } from '@/utils/index.js'

export default {
	components: {
		DragAndDrop: () => import('@/components/DragAndDrop.vue')
	},
	props: {
		docs : Array,
		refcontrato: Number,
	},
	data() {
		return {
			documento : null
		}
	},
	methods: {
		parseDate,
		isAudio( name ) {
			return name.includes('.mp3') || name.includes('.wav')
		},
		async download( doc ) {
			let {data} = await axios( { url: `/contratos/documento/${doc.ARCHIVO}`, responseType: 'blob' } )
			let ext = doc.ARCHIVO.split('.')[ doc.ARCHIVO.split('.').length - 1 ]
			downloadFile( new File([ data ], `${doc.NOMBRE}.${ext}`) )
		},
		async enviar() {
      axios({
        method: "POST",
        url: `/contratos/documento/${this.refcontrato}`,
        headers: { "Content-Type": "multipart/form-data" },
				data: {
					file: this.documento
				}
      })
        .then(() => {
          this.$emit("uploaded");
        })
        .catch(() => {
          this.$root.$emit("snack", this.$t('documentacion.error_subir'));
        });
    },
	}
}
</script>