<template>
	<v-container>
		<v-row>

			<v-col cols="12" md="4">
				<v-card outlined>
					<v-card-subtitle v-t="'audios_gana.venta'" ></v-card-subtitle>
					<v-divider></v-divider>
					<DragAndDrop multiple class="pa-2" v-model="files.venta" accept="audio/mp3,audio/wav" />
				</v-card>
			</v-col>
			<v-col cols="12" md="4">
				<v-card outlined>
					<v-card-subtitle v-t="'audios_gana.legal'" ></v-card-subtitle>
					<v-divider></v-divider>
					<DragAndDrop multiple class="pa-2" v-model="files.texto" accept="audio/mp3,audio/wav" />
				</v-card>
			</v-col>
			<v-col cols="12" md="4">
				<v-card outlined>
					<v-card-subtitle v-t="'audios_gana.certificado'" ></v-card-subtitle>
					<v-divider></v-divider>
					<DragAndDrop multiple class="pa-2" v-model="files.certificado" accept="*" />
				</v-card>
			</v-col>

			<v-col cols="12">
				<div class="d-flex">
					<v-btn class="mx-auto" color="primary" @click="enviar">
						<v-icon left>mdi-upload</v-icon>
						{{ $t('audios_gana.subir_todo') }}
						</v-btn>
				</div>
			</v-col>

			<v-col cols="12" v-if="nifNoDetectado.length">
				<v-alert type="error" text>
					{{ $t('audios_gana.nif_no_detectado') }}
					<div class="d-flex flex-wrap" style="gap: 4px">
						<v-chip color="error" outlined label small v-for="f, i in nifNoDetectado" :key="i">
							{{ f }}
						</v-chip>
					</div>
				</v-alert>
			</v-col>

			<v-col cols="12">
				<v-simple-table>
					<thead>
						<tr>
							<th>NIF</th>
							<th v-t="'audios_gana.venta'" ></th>
							<th v-t="'audios_gana.legal'" ></th>
							<th v-t="'audios_gana.certificado'" ></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="[key, val] in Object.entries(porSubir) ">
							<td>{{ key }}</td>
							<td>{{ val.venta }}</td>
							<td>{{ val.texto }}</td>
							<td>{{ val.certificado }}</td>
						</tr>
					</tbody>
				</v-simple-table>
			</v-col>

		</v-row>
	</v-container>
</template>

<script>
import { req, cups as validateCups, nif as validateNif } from '@/utils/validations'

export default {
	components: {
		DragAndDrop: () => import('../components/DragAndDrop.vue')
	},
	data() {
		return {
			cups: null,
			files: {
				texto: [],
				venta: [],
				certificado: []
			},
			rules: {
				req, validateCups, validateNif,
				cupsOrNif: (v) => validateCups(v) === true || validateNif(v) === true || 'No es ni un CUPS ni un Nif válido'
			}
		}
	},
	methods: {
		async enviar() {
			const formData = new FormData()

			for (const f of this.files.texto) {
				formData.append('texto', f)
			}
			for (const f of this.files.venta) {
				formData.append('venta', f)
			}
			for (const f of this.files.certificado) {
				formData.append('certificado', f)
			}

			await axios({
				method: 'POST',
				url: '/audios/gana',
				data: formData
			})

			this.$root.$emit(
				"snack",
				this.$i18n.t('audios_gana.subida_ok')
			);

			this.files = {
				texto: [],
				venta: [],
				certificado: []
			}

		},
		get_dni(str) {
			const DNI_REGEX = /(\d{8})([A-Z])/;
			const CIF_REGEX = /([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])/;
			const NIE_REGEX = /[XYZ]\d{7,8}[A-Z]/;
			return str.match(DNI_REGEX) || str.match(CIF_REGEX) || str.match(NIE_REGEX)
		}
	},
	computed: {
		nifNoDetectado() {
			let res = []

			for (const f of this.files.texto) {
				if (this.get_dni(f.name) == null)
					res.push(f.name)
			}
			for (const f of this.files.venta) {
				if (this.get_dni(f.name) == null)
					res.push(f.name)
			}
			for (const f of this.files.certificado) {
				if (this.get_dni(f.name) == null)
					res.push(f.name)
			}

			return res
		},

		porSubir() {
			let res = {}

			for (const f of this.files.texto) {
				let dni = this.get_dni(f.name)
				if (dni)
					dni = dni[0]
				else continue
				res[dni] = {
					...res[dni],
					texto: f.name
				}
			}
			for (const f of this.files.venta) {
				let dni = this.get_dni(f.name)
				if (dni)
					dni = dni[0]
				else continue
				res[dni] = {
					...res[dni],
					venta: f.name
				}
			}
			for (const f of this.files.certificado) {
				let dni = this.get_dni(f.name)
				if (dni)
					dni = dni[0]
				else continue
				res[dni] = {
					...res[dni],
					certificado: f.name
				}
			}

			return res
		}
	}
}
</script>