<template>
  <v-app v-if="$store.getters.getTenant">
    <v-app-bar dense class="elevation-0" app v-if="$store.getters.isLoggedIn" extension-height="24px">
      <router-link to="/" class="d-flex items-center align-center mr-4">
        <img width="100" :src="$store.getters.getTenant.logo" />
      </router-link>
      <!-- <h2 class="text-no-wrap mx-4">
        {{ $store.getters.getTenant.app }}
      </h2> -->

      <v-tabs v-if="!$vuetify.breakpoint.mobile" optional>
        <v-tab v-for="(tab, i) in menu" v-on="tab.events || {}" :key="i" exact :to="tab.link">
          <v-icon small left>{{ tab.icon }}</v-icon>
          {{ tab.title }}</v-tab>
      </v-tabs>

      <v-spacer />

      <template v-if="!$vuetify.breakpoint.mobile" >
        <v-btn icon @click="changeTheme" class="mr-2">
          <v-icon v-if="$vuetify.theme.dark">mdi-white-balance-sunny</v-icon>
          <v-icon v-else>mdi-weather-night</v-icon>
        </v-btn>
        <v-btn fab small color="error darken-2" @click.stop="logout">
          <v-icon small>mdi-exit-run</v-icon>
        </v-btn>
      </template>

      <v-btn v-if="$vuetify.breakpoint.mobile" icon @click.stop="burgerMenu = !burgerMenu">
        <v-icon>mdi-cog</v-icon>
      </v-btn>

      <template v-slot:extension>
        <div class="text-no-wrap" >{{ $route?.meta?.titulo || $route.name }}</div>
        <div class="text-right" style="width: 100%;">{{ $store.getters.getTokenPayload.nombre_comercial }}</div>
      </template>

    </v-app-bar>

    <v-navigation-drawer fixed temporary app v-model="burgerMenu">
      <v-tabs background-color="transparent" optional vertical>
        <!-- <v-tab style="background-color: transparent !important;" v-for="(tab, i) in menu" :key="i" exact :to="tab.link">{{
          tab.text
        }}</v-tab> -->

        <div class="pa-2 d-flex justify-end">
          <v-btn icon @click.stop="burgerMenu = !burgerMenu" small class="ml-auto">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-tab :color="link.color" v-on="link.events || {}" v-for="link in menu" exact-active-class="active-tab"
          class="rounded-r-pill mr-1" :to="link.link" :key="link.link">
          <v-list-item-icon class="my-auto">
            <v-icon v-text="link.icon" />
          </v-list-item-icon>
          <v-list-item-content style="font-size: 0.7rem">
            <div class="d-flex">{{ link.title }}</div>
          </v-list-item-content>
        </v-tab>
      </v-tabs>

      <template v-slot:append>
        <div class="pa-4 align-center d-flex flex-column" style="gap: 10px">
          <div class="d-flex">
            <img width="100%" :src="$store.getters.getTenant.logo" style="max-height: 200px;" />
          </div>
          <v-btn rounded block @click="changeTheme">
            <v-icon left v-if="$vuetify.theme.dark">mdi-white-balance-sunny</v-icon>
            <v-icon left v-else>mdi-weather-night</v-icon>
            Cambiar tema
          </v-btn>

          <v-btn rounded block color="error darken-2" @click.stop="logout">
            <v-icon left>mdi-exit-run</v-icon>
            Cerrar sesión
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main :style="`background: ${$vuetify.theme.dark ? '' : '#e4e4e4'}; overflow-y: auto `">

      <download-banner />

      <router-view @reload-menu="reloadMenu" />
    </v-main>

    <v-snackbar v-model="snack.show" timeout="4000" v-bind="snack.props" >
      {{ snack.msg }}
    </v-snackbar>

    <portal-target name="filter-btn"></portal-target>


    <!-- <v-footer v-if="$store.getters.isLoggedIn" app  padless > -->
    <bottom-navigation @open-menu="burgerMenu = !burgerMenu" color="app"
      v-if="$vuetify.breakpoint.mobile && $store.getters.isLoggedIn" :link="$route.path" />
    <!-- <v-card-text class="py-3 text-center">
        {{ new Date().getFullYear() }} —
        <strong>{{ $store.getters.getTenant.name }}</strong>
        {{ $store.getters.getTokenPayload.nombre_comercial }}
        [ {{ $store.getters.getTokenPayload.email }} ]
        {{ $store.getters.getTokenPayload.nif }}
      </v-card-text> -->
    <!-- </v-footer> -->

    <v-dialog transition="dialog-bottom-transition" fullscreen v-model="scanningQr" @close="scanningQr = false" >
      <ScannerQr v-model="qr" />
    </v-dialog>

    <ConfirmDialog ref="confirm" />
  </v-app>
</template>

<script setup>
import Vue, { getCurrentInstance, onMounted, ref, watch, computed, reactive } from 'vue'

import axios from 'axios'
import $axios from '@/utils/axiosWrapper'

import ConfirmDialog from "@/components/ConfirmDialog.vue"
import DownloadBanner from "@/components/DownloadBanner.vue"
import BottomNavigation from "@/components/BottomNavigation.vue"
import ScannerQr from "@/components/ScannerQr.vue"

const store = getCurrentInstance().proxy.$store
const vuetify = getCurrentInstance().proxy.$vuetify
const refs = getCurrentInstance().proxy.$refs
const meta = getCurrentInstance().proxy.$meta()
const root = getCurrentInstance().proxy.$root
const router = getCurrentInstance().proxy.$router
const i18n = getCurrentInstance().proxy.$i18n

const { set } = meta.addApp('custom')
const qr = ref(null)
watch(qr, console.log)

function getTenant() {
  return new Promise(async (resolve) => {
    const { data: tenant } = await axios({
      url: `${process.env.VUE_APP_API_URL}/tenant`,
      headers: {
        tenant: window.location.hostname,
      },
    });
    const name = tenant.slug;
    const dark = tenant.theme.dark;
    const light = tenant.theme.light;
    Object.keys(dark).forEach((i) => {
      vuetify.theme.themes.dark[i] = dark[i];
    });
    Object.keys(light).forEach((i) => {
      vuetify.theme.themes.light[i] = light[i];
    });
    vuetify.theme.themes.name = name;

    document.querySelector('meta[name="theme-color"]').setAttribute("content", vuetify.theme.themes.light.primary);

    i18n.locale = tenant.pais.toLowerCase()

    await store.dispatch("setTenant", tenant);
    resolve();
  });
}

const snack = reactive({
  show: false,
  msg: '',
  props: {},
  defaultProps : {
    color: 'primary',
  }
})
const burgerMenu = ref(false)

const { data: resumen, pending: loading, refresh: reloadMenu } = $axios({ url: '/resumen', default: () => ({ estados_llamadas: [] }) })

async function linkDonvatio() {
  // const { data: { url } } = await axios({
  //   method: 'POST',
  //   url: 'https://donvatio.es/api/auth/invitar_extranet',
  //   data: {
  //     extranet: store.getters.getTenant?.slug,
  //     email: store.getters.getTokenPayload.email,
  //   },
  //   block: true
  // })
  const { data } = await $axios({
    method: 'POST',
    url: `${process.env.VUE_APP_API_URL}/auth/donvatio_magic_link`,
    block: true
  })

  window.open(data.value['url'], '_blank')
}
const scanningQr = ref(false)

const menu = computed(() => [
  {
    title: i18n.t('menu.inicio'),
    mod: true,
    color: 'primary',
    text: i18n.t('menu.inicio'),
    link: '/',
    alert: false,
    icon: 'mdi-home',
    icon_inactive : 'mdi-home-outline',
    main: false,
    bottom: 1,
  },
  {
    title: i18n.t('menu.contratos'),
    mod: root.modules("CONTRATOS") && root.acceso('CONTRATOS'),
    color: "primary",
    text: resumen.value.contratosDetenidos
      ? i18n.tc('menu.tarjetas.contratos', resumen.value.contratosDetenidos == 1, {n : resumen.value.contratosDetenidos})
      : i18n.tc('menu.tarjetas.contratos', 2),
    link: "/contratos",
    alert: resumen.value.contratosDetenidos,
    icon: "mdi-file-document",
    icon_inactive: "mdi-file-document-outline",
    bottom: 4,
  },
  {
    title: i18n.t('menu.borradores'),
    mod: root.modules("BORRADORES") && root.acceso('BORRADORES'),
    color: "primary",
    text: resumen.value.borradoresSinEnviar
    ? i18n.tc('menu.tarjetas.borradores', resumen.value.borradoresSinEnviar == 1, {n : resumen.value.borradoresSinEnviar})
      : i18n.tc('menu.tarjetas.borradores', 2),
    link: "/borradores",
    alert: resumen.value.borradoresSinEnviar,
    icon: 'mdi-plus-thick',
    icon_inactive: 'mdi-plus-outline',
    bottom: 3,
  },
  {
    title: i18n.t('menu.facturas'),
    mod: root.modules("FACTURAS") && root.acceso('FACTURAS'),
    color: "primary",
    text: resumen.value.facturasPendientes
    ? i18n.tc('menu.tarjetas.facturas', resumen.value.facturasPendientes == 1, {n : resumen.value.facturasPendientes})
      : i18n.tc('menu.tarjetas.facturas', 2),
    link: "/facturas",
    alert: resumen.value.facturasPendientes,
    icon: "mdi-circle-multiple",
    icon_inactive: "mdi-circle-multiple-outline",
    bottom: 5,
  },
  {
    title: i18n.t('menu.master'),
    mod: root.modules("MASTER") && root.acceso('MASTER'),
    color: "primary",
    text: resumen.value.facturasPendientes
    ? i18n.tc('menu.tarjetas.master', resumen.value.facturasPendientes == 1, {n : resumen.value.facturasPendientes})
      : i18n.tc('menu.tarjetas.master', 2),
    link: "/master",
    alert: resumen.value.facturasPendientes,
    icon: "mdi-circle-multiple",
    icon_inactive: "mdi-circle-multiple-outline",
    bottom: 5,
  },
  {
    title: i18n.t('menu.verificaciones'),
    mod: root.modules("VERIFICACIONES"),
    color: "primary",
    text: resumen.value.verificacionesPendientes
    ? i18n.tc('menu.tarjetas.verificaciones', resumen.value.verificacionesPendientes == 1, {n : resumen.value.verificacionesPendientes})
      : i18n.tc('menu.tarjetas.verificaciones', 2),
    link: "/contratos",
    alert: resumen.value.verificacionesPendientes,
    icon: 'mdi-check'
  },
  {
    title: i18n.t('menu.llamadas'),
    mod: false && store.getters.getTenant?.slug == 'albor_energia' && root.acceso('CONTRATOS'),
    color: "primary",
    text: Vue.component('tabla_llamadas', {
      render: h => h(VSimpleTable, { props: { dense: true } }, [
        h('thead', [h('tr', [h('th', { class: 'text-left' }, ['Estado']), h('th', { class: 'text-right' }, ['Contratos'])])]),
        h('tbody', resumen.value.estados_llamadas.map(({ llamada, n }) => h('tr', [
          h('th', { class: 'text-left' }, llamada),
          h('td', { class: 'text-right' }, n)
        ]
        ))
        )
      ])
    }),
    link: "/contratos",
  },
  {
    title: i18n.t('menu.audios_gana'),
    color: "primary",
    mod: root.acceso('AUDIOS_GANA'),
    text: '',
    link: '/audios/gana',
    alert: false,
    icon: 'mdi-headphones'
  },
  {
    title: i18n.t('menu.documentos'),
    color: 'primary',
    mod: root.modules('DOCUMENTOS') && root.acceso('DOCUMENTOS'),
    text: i18n.t('menu.tarjetas.documentos'),
    link: '/descargas',
    alert: false,
    icon: 'mdi-download'
  },
  {
    title: i18n.t('menu.leads'),
    color: 'primary',
    mod: root.modules('LEADS') && root.acceso('LEADS'),
    text:  i18n.t('menu.tarjetas.leads', [resumen.value.leads] ),
    link: '/leads',
    alert: false,
    icon: 'mdi-account'
  },
  {
    title: i18n.t('menu.chat'),
    color: 'primary',
    mod: root.modules('TICKETING') && root.acceso('TICKETING'),
    text: i18n.t('menu.tarjetas.chat', [resumen.value.mensajes_sin_leer]),
    alert: resumen.value.mensajes_sin_leer,
    link: '/chat',
    icon: 'mdi-forum',
    icon_inactive: 'mdi-forum-outline',
    bottom: 2
  },
  {
    title: i18n.t('menu.comparativas'),
    color: '#4357ad',
    mod: root.modules('COMPARATIVAS') && root.acceso('COMPARATIVAS') ,
    text: i18n.t('menu.tarjetas.comparativas'),
    // link: this.linkDonvatio
    btnText: 'Abrir DonVatio',
    btnClass: 'white--text',
    events: {
      click: linkDonvatio
    },
    alert: false,
    icon: '$donvatio'
  },
  // {
  //   title: i18n.t('menu.comparativas_qr'),
  //   color: '#4357ad',
  //   mod: root.modules('COMPARATIVAS-QR') && root.acceso('COMPARATIVAS-QR') ,
  //   text: i18n.t('menu.tarjetas.comparativas'),
  //   // link: this.linkDonvatio
  //   btnText: i18n.t('menu.comparativas_qr'),
  //   btnClass: 'white--text',
  //   events: {
  //     click: () => scanningQr.value = true
  //   },
  //   alert: false,
  //   icon: 'mdi-qrcode-scan'
  // },
].filter((sec) => sec.mod))

watch(menu, () => { store.dispatch('setMenu', menu.value) })

async function logout() {
  (await refs.confirm.open(
    "Cerrar sesión",
    "¿Estás seguro de que quieres cerrar sesión?",
    { color: "app", confirmColor: "app" }
  ))
    ? store
      .dispatch("logout")
      .then(() => router.push({ name: "Login" }))
    : null;
}
function changeTheme() {
  vuetify.theme.dark = !vuetify.theme.dark;
  localStorage.setItem("theme", vuetify.theme.dark);
}

onMounted(() => {

  getTenant();

  set({
    meta: [{ charset: 'utf=8' }],
    title: store?.getters?.getTenant?.app
  })

  root.$on("snack", (_msg, options) => {
    snack.show = false
    snack.show = true
    snack.msg = _msg
    snack.props = options ? options : snack.defaultProps
  });

})

const colors = computed(() => vuetify.theme.themes.dark)

</script>

<style lang="scss" >
.d-grid {
  display: grid;
}

.h-100 {
  height: 100%;
}

.v-toolbar__extension {
  // @extend .primary;
  // background: #1d1d1d;
  background: v-bind( 'colors.app' );
  color: white;
}
</style>