<template>
	<v-text-field v-model="val" v-on="$listeners" outlined hide-details="auto" type="number" min="0"
		v-bind="{ ...input, ...$attrs }"></v-text-field>
</template>

<script>
export default {
	props: ['value', 'input'],
	data() {
		return {
			localValue: this.value
		}
	},
	computed: {
		val: {
			get() { return this.value },
			set(v) {
				this.localValue = v
				// v = v.replace(',', '.')
				// this.localValue = isNaN(v) ? v : String(Number(v))
				// this.$emit('input', String(Number(v.replace(',', '.'))) ) }
			}
		},
	},
	watch: {
		localValue(value) {
			this.$emit('input', value)
		},
		value(value) {
			this.localValue = value
		}
	}
}
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>