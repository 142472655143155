<template>
  <v-switch v-model="val" outlined hide-details="auto" class="mt-0" v-bind="{...input, ...$attrs}" ></v-switch>
</template>

<script>
  export default {
   props: ['value', 'input'],
   computed: {
		val: {
			get() { return this.value },
			set(v) { this.$emit('input', v) }
		}
	},
  };
</script>
