<template>
  <v-alert
    :elevation="1"
    colored-border
    border="bottom"
    :color="message.userColor"
    :class="{ message: true, own: own, 'pa-0': true, 'msg--caret': true }"
  >
    <v-card-subtitle class="py-2">
      <span
        :style="`color: ${message.userColor}`"
        class="font-weight-bold mr-2"
        >{{ message.nombre }}</span
      >
      {{ new Date(message.fechaEnvio).toLocaleString() }}
      <!-- <status-chip class="ml-1" x-small :value="message.tipo" v-if="message.tipo" /> -->
    </v-card-subtitle>

    <v-divider />

    <div v-if="message.mensaje" class="px-4 py-2 msg--text" v-html="message.mensaje" />
  </v-alert>
</template>

<script>
import { parseDate } from "@/utils/index.js";

export default {
  props: {
    message: {
      type: Object,
    },
  },
  data() {
    return {
      deleting: false,
    };
  },
  components: {
  },
  computed: {
    own() {
      return (
        this.$store.getters.getTokenPayload.refcanal == this.message.refcolaborador
      );
    },
    comercial() {
      return this.message.idUsuario == this.message.idComercial;
    },
  },
  methods: {
    parseDate,
    // descargarArchivo({ idArchivo, filename }) {
    //   axios({
    //     method: "GET",
    //     url: `${process.env.VUE_APP_API_URL}/chat/download_file/${this.message.refcontrato}/${idArchivo}`,
    //     responseType: "blob",
    //   }).then((res) => {
    //     let extension = filename
    //       .split(".")
    //       [filename.split(".").length - 1].toLowerCase();

    //     let file = new File([res.data], filename.substring(filename.indexOf("_") + 1), {
    //       type: extensions[extension],
    //     });

    //     let download = true;
    //     if (["pdf", "png", "jpg", "jpeg", "gif"].includes(extension))
    //       download = false;

    //     downloadFile(file, !download);
    //     this.downloadingFile = false;
    //     // window.open(fileURL);
    //   });
    // },
    async eliminar() {
      if (! await this.$refs.confirm.open("Eliminar mensaje", "¿Seguro que quieres eliminar este mensaje?", { color: 'error', confirmColor: 'error',})) return;
      this.deleting = true;
      axios({
        method: "delete",
        url: `${process.env.VUE_APP_API_URL}/chat/${this.message.idMensaje}`,
      }).then((res) => {
        this.$emit("reload");
        this.deleting = false;
      });
    },
  },
};
</script>

<style lang="scss">
.message {
  align-self: start;
  min-width: 300px;
  max-width: calc(100% - 20px);
  .menu-btn {
    float: right;
  }
  margin-bottom: 3px;
  .v-alert__border {
    border-width: 1.25px;
  }
  .msg--img {
    display: inline-flex;
    width: 100%;
    max-width: 300px;
  }
}
.own {
  align-self: end;
  .menu-btn {
    float: left;
  }
  .msg--text {
    // text-align: right;
  }
}
// .v-alert__content {
//   display: flex;
//   flex-direction: column;
// }

.d-grid {
  display: grid;
  // grid-gap: 10px;
}
</style>
