<template>
  <v-container fluid>
    <!-- <TopCard
          icon="mdi-file-outline"
          texto="Nº Contratos pendientes"
          :value="12"
          unit=" contratos"
        /> -->

    <v-row>

      <v-col v-if="0 && !$vuetify.breakpoint.mobile" cols="12" sm="8" offset-sm="2">
        <div class="d-flex flex-wrap align-start justify-space-around" style="gap: 30px">
          <v-card v-for="(section, i) in menu" :key="i" class="flex-grow-1" outlined style="width: 350px">
            <v-alert class="mb-0" outlined :color="section.color">
              <v-card-title> {{ section.title }} </v-card-title>
              <component v-if="(typeof section.text == 'function')" :is="section.text" />
              <v-card-text v-else> {{ section.text }} </v-card-text>
              <v-card-actions>
                <v-btn block :to="section.link" v-on="section.events || {}" :class="section.btnClass" rounded
                  :color="section.color">
                  {{ section.btnText || 'Ver' }}
                  <v-icon>mdi-chevron-double-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-alert>
          </v-card>
        </div>
      </v-col>

      <v-col cols="12" sm="8" offset-sm="2">
        <v-row>
          <v-col :key="i" v-for="section, i in menu" class=""
            style="justify-items: center" cols="4" sm="4" lg="2" >
            <!-- <v-btn :to="section.link" v-on="section.events || {}" :class="section.btnClass" fab rounded :elevation="0"
              :color="section.color" x-large>
              <v-badge color="error darken-2" :value="section.alert" :content="section.alert">
                <v-icon large>{{ section.icon }}</v-icon>
              </v-badge>
            </v-btn>
            <span class="mt-1">{{ section.title }}</span>  -->

            <v-card :to="section.link" v-on="section.events || {}" height="100%" style="aspect-ratio: 1; align-items: center; width: 100%;"  class="pa-4 d-flex flex-column text-center items-center justify-center rounded-xl " :elevation="0">
              <v-badge overlap color="error darken-2" :value="section.alert" :content="section.alert">
                <v-icon :color="section.color" :x-large="$vuetify.breakpoint.smAndUp" :large="$vuetify.breakpoint.xsOnly" >{{ section.icon }}</v-icon>
              </v-badge>
              <span class="mt-2">{{ section.title }}</span>
            </v-card>

          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" sm="8" offset-sm="2" v-if="texto" >
        <v-sheet v-html="texto" class="pa-2 rounded" ></v-sheet>
      </v-col>

      <v-col v-if="proxima_factura !== null" cols="12" sm="8" offset-sm="2">
        <v-card class="d-flex pa-4" flat >
          <div class="d-grid flex-grow-1">
            <span class="mb-0" >Mi próxima factura</span>
            <span class="text-h5 mt-1 font-weight-bold" >{{ money.format(proxima_factura) }}</span>
          </div>
          <div class="d-flex justify-center align-center ">
            <v-btn small flat color="primary" rounded to="/contratos" >
              Más info
              <v-icon right >mdi-arrow-right</v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" sm="8" offset-sm="2" v-if="noticias.length" >
        <v-card flat class="pa-2 rounded">
          <v-card-title class="primary--text" v-t="'noticias.novedades'" ></v-card-title>
          <v-divider class="primary" />

          <Noticia v-for="n in noticias" :noticia="n" :key="n.REF" />
          <!-- <div class="mt-4" v-for="n in noticias" >
            <h3>{{ n.TITULO }}</h3>
            <v-chip label small >{{ parseDate(n.FECHA) }}</v-chip>
            <span> - </span>
            <span>{{ n.NOTICIA.slice(0, 100) }} {{ n.NOTICIA.length > 100 ? '...' : '' }}</span>
            <v-btn class="d-block" small color="primary" text rounded >Leer más</v-btn>
          </div> -->

        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script setup>
import { getCurrentInstance, computed, defineEmits, onMounted, ref } from 'vue'
import axios from '@/utils/axiosWrapper'
import Noticia from '../components/Noticia.vue';
import { parseDate } from '@/utils/index'

const store = getCurrentInstance().proxy.$store;
const router = getCurrentInstance().proxy.$router;
const menu = computed(() => store.getters.getMenu.filter(m => m.main !== false))

const emits = defineEmits(['reload-menu'])
const { data : texto } = axios({ url: '/resumen/home', lazy: false } )
const { data : noticias } = axios({ url: '/resumen/noticias', lazy: false, default: () => ([]) } )
const { data : proxima_factura } = axios({ url: '/resumen/proxima_factura', lazy: false, default: () => (null) } )

const money = Intl.NumberFormat("DE-de", {
  style: "currency",
  currency: "EUR",
});

// const texto = ref(`<h3 class='d-flex' >Bienvenido a <img class="ml-2" width="100" src="${store.getters.getTenant.logo}"></h3>`)
// const noticias = ref()

onMounted( () => {emits('reload-menu')} )

</script>

<style></style>