// import axios from "axios";
import { AxiosError } from "axios";
import { Ref, ref } from "vue";

function updateOptions(options, url) {
  if (options === undefined) return { url };
  options.url = url;
  return options;
}

type httpResponseAxios = {
  data: Ref<any>;
  error: Ref<Error | Boolean | undefined>;
  pending: Ref<Boolean>;
  refresh: () => void;
};

export default function httpRequest(...args: any[]): httpResponseAxios {
  let [url, options] = args;
  if (!options && typeof url === "object") {
    options = url;
    url = options.url;
  }

  options = updateOptions(options, url);

  const data = ref<any>(null);
  if (options.default) data.value = options.default();

  const error = ref<Error | Boolean | undefined>();
  const pending = ref<Boolean>(false);

  const makeRequest = async (extra_options?: Object) => {
    options = { ...options, ...extra_options };
    pending.value = true;
    try {
      const { data: json } = await window.axios(options);
      if (options.transform) data.value = options.transform(json);
      else data.value = json;
      pending.value = false;
      return true;
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.request?.responseType == "blob")
          error.value = JSON.parse(await e.response.data.text());
        else if (options.transformError)
          error.value = await options.transformError(e);
        else error.value = e.response;
      }
      pending.value = false;

      return false;
    }
  };

  if (!options.lazy) makeRequest();
  // if (!options.lazy) {
  //   if (options.block) async () => await makeRequest();
  //   else if (!options.block) makeRequest();
  // }

  return {
    data,
    error,
    pending,
    refresh: makeRequest,
  };
}
