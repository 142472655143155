<template>
  <v-container>
    <v-form style="max-width: 900px; margin: auto" class="pa-2" @submit.prevent="login" ref="login">
      <v-card>
        <v-sheet color="primary">
          <v-card-title class="white--text">{{ $store.getters.getTenant.app }}</v-card-title>
        </v-sheet>
        <v-row class="mt-6">
          <v-col cols="12" md="6">
            <v-img class="logo mb-4" contain :src="$store.getters.getTenant.logo" max-height="300" alt="logo" />
          </v-col>
          <v-col cols="12" md="6">
            <div class="pa-4 pt-0">
              <v-alert class="mt-2" v-model="error" type="error" v-t="'login.error'"></v-alert>

              <v-text-field v-model="usuario" filled :label="$t('login.usuario')" name="username" type="text" dense hide-details="auto"
                class="my-4" :rules="[rules.req]"></v-text-field>
              <v-text-field v-model="pass" filled :label="$t('login.pass')" name="password"
                :type="showPassword ? 'text' : 'password'" dense hide-details="auto" class="my-4" :rules="[rules.req]">
                <template v-slot:append>
                  <v-btn @click.stop="showPassword = !showPassword" icon>
                    <v-icon v-if="!showPassword">mdi-eye</v-icon>
                    <v-icon v-else>mdi-eye-off</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
              <v-btn type="submit" block color="primary" :loading="loading">{{ $t('login.login') }}</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>

      <div class="d-flex flex-column">
        <hr class="mt-6 flex-grow-1" style="
            background: var(--v-primary-base);
            height: 5px;
            border: none;
            border-radius: 5px;
          " />
        <div>
          <v-icon left small>mdi-copyright</v-icon>
          <b>{{ new Date().getFullYear() }}</b>
        </div>
      </div>
    </v-form>

    <v-dialog :value="!!msg" max-width="600" persistent>
      <v-card class="pa-4">
        <v-card-title v-text="msg" />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="msg = false">{{ $t('common.aceptar') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { req } from '@/utils/validations'

export default {
  data() {
    return {
      portal: ((
        s = window.location.host.replaceAll(/www./g, "").split(".")[0]
      ) => s && s[0] && s[0].toUpperCase() + s.slice(1))(),
      usuario: null,
      pass: null,
      error: false,
      empresa: null,
      loading: false,
      modalEmpresa: false,
      msg: this.$route.query.msg,
      development: process.env.NODE_ENV == "development",
      empresas: [],
      rules: { req },
      showPassword: false,
    };
  },
  methods: {
    login() {
      if (!this.$refs["login"].validate()) return;
      this.loading = true;
      this.$store
        .dispatch("login", {
          user: this.usuario,
          password: this.pass,
        })
        .then(() => {
          this.$router.push(this.$route.query.redirect || "/");
          this.loading = false;
        })
        .catch(() => {
          this.error = true;
          this.loading = false;
          this.pass = "";
        });
    },
  },
};
</script>

<style>
.logo {
  max-width: 450px;
  margin: auto;
}
</style>
