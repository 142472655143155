<template>
  <v-textarea v-model="val" outlined auto-grow no-resize hide-details="auto" v-bind="{...input, ...$attrs}" ></v-textarea>
</template>

<script>
  export default {
   props: ['value', 'input'],
   computed: {
		val: {
			get() { return this.value },
			set(v) { this.$emit('input', v) }
		}
	},
  };
</script>
