<template>
  <div>
    <v-row>
      <v-col>
    <drag-and-drop v-model="documentos" :multiple="false"></drag-and-drop>
      </v-col>
      <v-col v-if="borrador.archivo" cols="4">
        <v-card outlined class="pa-2" rounded>
          <v-card-text>{{ borrador.archivo }}</v-card-text>
          <v-card-actions class="justify-center">
            <v-btn @click.stop="verDocumentoSubido">
              <v-icon left>mdi-magnify</v-icon>
              Ver documento
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-4" v-if="documentos">
      <v-col cols="12" sm="4" offset-sm="4" >
        <v-card outlined class="pa-2" rounded>
          <v-card-title class="text-no-wrap">{{ documentos.name }}</v-card-title>
          <v-card-subtitle>{{
            humanFileSize(documentos.size)
          }}</v-card-subtitle>
        </v-card>
        <!-- <v-img :src="thumbnail"></v-img> -->

        <v-card-actions class="justify-center">
          <v-btn @click="verDocumento" color="primary">
            <v-icon left>mdi-eye</v-icon>
            Ver
          </v-btn>
          <v-btn @click="borrarDocumento" color="error darken-2">
            <v-icon left>mdi-delete</v-icon>
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { downloadFile } from "@/utils/index.js";
import extensions from "@/utils/mimeExtensions.js";

export default {
  components: {
    DragAndDrop: () => import("@/components/DragAndDrop.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  props: {
    value: { required: true },
    idEstudio: Number | String,
    multiple: Boolean,
    borrador : Object
  },

  data() {
    return {
      documentosHeader: [
        { text: "Nombre", value: "name" },
        { text: "Acciones", value: "acciones", sortable: false },
      ],
      downloadingFile: false,
      sendingFile: false,
      newFile: [],
    };
  },
  computed: {
    documentos: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    async subirDocumento() {
      if (!this.idEstudio) return;
      for (let f of this.newFile) {
        try {
          await this.changeFileNameF(f.name);
          this.changeFileName.show = false;
        } catch (e) {
          this.changeFileName.show = false;
          this.sendingFile = false;
          f = null;
          return;
        }
        f = new File([f], this.changeFileName.newName, {
          type: f.type,
        });

        let formData = new FormData();
        formData.append(
          "documentoName",
          this.changeFileName.newName + this.changeFileName.extension
        );
        formData.append("file", f);
        formData.append("final", this.final ? 1 : 0);

        try {
          this.sendingFile = true;
          await axios({
            method: "POST",
            url: `/documentacion/${this.idEstudio}`,
            data: formData,
          });
          f = null;
          this.sendingFile = false;
          this.getDocumentacion();
        } catch (e) {
          if (e.response.status == 413) {
            this.$root.$emit(
              "snack",
              "El documento es demasiado grande para ser subido"
            );
            this.sendingFile = false;
            f = null;
            return;
          }
          this.$root.$emit("snack", "No se ha podido subir el archivo");
          this.sendingFile = false;
          f = null;
          return;
        }
      }

      this.newFile = [];
    },
    humanFileSize(bytes, dp = 1) {
      const thresh = 1000;

      if (Math.abs(bytes) < thresh) {
        return bytes + " B";
      }

      const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      let u = -1;
      const r = 10 ** dp;

      do {
        bytes /= thresh;
        ++u;
      } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        u < units.length - 1
      );

      return bytes.toFixed(dp) + " " + units[u];
    },
    verDocumento() {
      const name = this.value.name;
      let extension = name.split(".")[name.split(".").length - 1].toLowerCase();

      const file = new File([this.value], name, {
        type: extensions[extension],
      });
      downloadFile(file, true);
    },
    async verDocumentoSubido() {
      const { data } = await axios({
        method: "GET",
        url: `/contratos/documento/${this.borrador.archivo}`,
        responseType: "blob",
      });

      downloadFile(data, true);
    },
    async borrarDocumento() {
      this.$emit('input', null)
    },
  },
};
</script>
