<template>
  <v-autocomplete
    v-model="val"
    outlined
    clearable
    hide-details="auto"
    v-bind="{ ...input, ...$attrs }"
    :items="filteredItems"
    small-chips
  ></v-autocomplete>
</template>

<script>
export default {
  props: ["value", "input", "borrador"],
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    filteredItems() {
      let items = this.$attrs["items"];
			const borr = this.borrador;
      return items.filter( item => {
				if (typeof item == 'string') return true 
				if ( !item.filter_key ) return true
				return item.filter_value.includes( borr[ item.filter_key ] )
			} )
    },
  },
};
</script>
