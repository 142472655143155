// import donvatio from '@/assets/logo.svg'
import DonVatio from "@/components/icons/donvatio.vue";

const icons = {
  // donvatio: (<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 87.5 100'>
  // 	<polyline fill='#1697f6' points='43.75 0 23.31 0 43.75 48.32'/>
  // 	<polygon 	fill='#7bc6ff' points='43.75 62.5 43.75 100 0 14.58 22.92 14.58 43.75 62.5'/>
  // 	<polyline fill='#1867c0' points='43.75 0 64.19 0 43.75 48.32'/>
  // 	<polygon 	fill='#aeddff' points='64.58 14.58 87.5 14.58 43.75 100 43.75 62.5 64.58 14.58'/>
  // </svg>)
  donvatio: {
    component: DonVatio,
    props: {
      name: "donvatio",
    },
  },
};

export default icons;
