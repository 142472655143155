import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import {es} from 'vuetify/es5/locale'
import customIcons from '@/utils/customIcons'

Vue.use(Vuetify);

const mq = window.matchMedia('(prefers-color-scheme: dark)')
const storedTheme = localStorage.getItem('theme') || false;
const dark = storedTheme === null ? mq.matches : JSON.parse(storedTheme)

const tenant = localStorage.getItem('tenant') ?  JSON.parse(localStorage.getItem('tenant')) : {}

export default new Vuetify({
  theme : {
    dark,
    options: { customProperties: true },
    themes: {
      ...tenant.theme
    }
  },
  icons: {
    iconfont: 'mdi',
    values: customIcons
  },
  lang: {
		locales: {es},
		current : 'es'
	}
});
