<template>
  <v-card :style="`background: ${$vuetify.theme.dark ? '' : '#e4e4e4'};`">
    <v-container>
      <v-row>
        <v-col cols="12" md="4" class="d-flex flex-column">
          <v-card class="pa-3 mb-3">
            <h2 class="title">{{ $t('facturas.estado') }}</h2>
            <v-divider></v-divider>
            <div class="d-flex">
              <EstadoFactura
                x-large
                label
                class="flex-grow-1 text-center mt-4"
                :estado="factura.estadofactura"
              />
            </div>
            <h3 class="pl-2 mt-1">{{ factura.explicacion_estado }}</h3>
            <div class="d-flex flex-wrap justify-space-between mt-4">
              <InfoInput
                :title="$t('facturas.pago_fac')"
                :value="parseDate(factura.fechapago, false, true, true)"
              />
              <InfoInput :title="$t('facturas.cuenta_pago')" :value="factura.cuentabancaria" />
            </div>
            <div class="mt-4">
              <v-btn block @click.stop="verDocumento" color="primary">
                <v-icon left>mdi-text-box</v-icon>
                {{$t('facturas.ver_doc')}}
              </v-btn>
              <v-btn block @click.stop="descargarDesglose" color="primary" class="mt-2">
                <v-icon left>mdi-microsoft-excel</v-icon>
                {{$t('facturas.desglose')}}
              </v-btn>
            </div>
          </v-card>
        </v-col>
        <v-col v-if="factura.estadofactura == 'PENDIENTE DOCUMENTO'" cols="12" md="8">
          <SubidaDocumento :factura="factura" @reload="getFactura" />
        </v-col>
        <v-col cols="12" md="8" class="d-flex flex-column">
          <v-card class="pa-3 mb-3">
            <v-row>
              <v-col cols="12">
                <h2 class="title">{{ $t('facturas.datos') }}</h2>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" sm="4">
                <InfoInput
                  :title="$t('facturas.razon_social')"
                  :value="factura.nombrefiscalcolaborador"
                />
                <InfoInput :title="$t('facturas.nif')" :value="factura.nifcolaborador" />
                <InfoInput
                :title="$t('facturas.fecha_fac')"
                  :value="factura.fechafactura"
                />
                <InfoInput
                :title="$t('facturas.n_doc')"
                  :value="factura.numerofactura"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <InfoInput
                :title="$t('facturas.bimp')"
                  :value="factura.bifactura | eur"
                />
                <InfoInput
                :title="$t('facturas.iva', { pct: factura.pivafactura })"
                  :value="factura.ivafactura | eur"
                />
                <InfoInput
                :title="$t('facturas.irpf', { pct: factura.pirpffactura })"
                  :value="factura.irpffactura | eur"
                />
                <InfoInput
                :title="$t('facturas.total')"
                  :value="factura.totalfactura | eur"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <InfoInput
                :title="$t('facturas.dir')"
                  :value="factura.direccioncolaborador"
                />
                <InfoInput
                :title="$t('facturas.pob')"
                  :value="factura.poblacioncolaborador"
                />
                <InfoInput
                :title="$t('facturas.prov')"
                  :value="factura.provinciacolaborador"
                />
                <InfoInput :title="$t('facturas.cp')" :value="factura.cpcolaborador" />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card class="pa-3 mb-3">
            <div class="d-flex justify-space-between flex-wrap">
              <h2 class="title">{{ $t('facturas.contenido') }}</h2> <h5>[ {{ $tc('facturas.lineas', Number(Boolean(factura.lineas?.length)), { n : factura.lineas?.length } ) }} ]</h5>
            </div>
            <v-divider></v-divider>
            <v-simple-table dense>
              <tbody>
                <tr v-for="linea in factura.lineas" :key="linea.ref">
                  <td>{{ linea.fechacontrato | date }}</td>
                  <td>{{ linea.cupscontrato }}</td>
                  <td>{{ linea.nombrecontrato }}</td>
                  <td>{{ linea.tarifacontrato }}</td>
                  <td>{{ linea.importecontrato | eur }}</td>
                  <td>
                    <EstadoContrato small :estado="linea.estado" />
                  </td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          :to="`/contratos?_detailsId=${linea.refcontrato}`"
                          fab
                          x-small
                        >
                          <v-icon small>mdi-arrow-right-bold</v-icon>
                        </v-btn>
                      </template>
                      {{ $t('facturas.ver_contrato') }}
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>

        <v-col cols="12" md="8">
          <v-card class="pa-3 mb-3">
            <h2 class="title">{{ $t('facturas.hist') }}</h2>
            <v-divider class="mb-4"></v-divider>

            <v-timeline dense>
              <v-timeline-item v-for="hist in factura.historico" :key="hist.Id">
                <v-card class="pa-2">
                  <v-card-subtitle class="pa-0"
                    >{{ parseDate(hist.fecha, false, true, true) }} -
                    {{ hist.nombreadministrador }}</v-card-subtitle
                  >
                  <v-card-title class="pa-0">{{ hist.asunto }}</v-card-title>
                  <v-card-text class="pa-0" v-if="hist.comentario">{{
                    hist.comentario
                  }}</v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { downloadFile, parseDate } from "@/utils/index.js";

export default {
  props: {
    detailsId: String | Number,
  },
  components: {
    EstadoContrato: () =>
      import("@/modules/contratos/components/EstadoContrato.vue"),
    EstadoFactura: () => import("./EstadoFactura.vue"),
    InfoInput: () => import("../components/InfoInput.vue"),
    SubidaDocumento: () => import("../components/SubidaDocumento.vue"),
  },
  data() {
    return {
      factura: {},
    };
  },
  methods: {
    parseDate,
    async getFactura() {
      if (!this.detailsId) return;
      const { data } = await axios({
        method: "GET",
        url: `/facturas/${this.detailsId}`,
      });
      this.factura = data;
    },

    async verDocumento() {
      const { data } = await axios({
        method: "GET",
        url: `/facturas/documento/${this.factura.archivo}`,
        responseType: "blob",
      });

      downloadFile(data, true);
    },
    async descargarDesglose() {
      const { data } = await axios({
        method: "GET",
        url: `/facturas/desglose/${this.factura.ref}`,
        responseType: "blob",
      });
  
      downloadFile(new File([data], `${ this.$t('facturas.desglose').replaceAll(' ', '_').replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase() }_${this.factura.ref}.xlsx`), false);
    }
  },
  mounted() {
    this.getFactura();
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: var(--v-primary-base);
  font-size: 1.6rem !important;
}
</style>
